import React from "react"
import { Navigation } from "../components/navigation"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import "../components/layout-tailwind.css"

const NotFoundPage = () => {
  const notFoundData = useStaticQuery(graphql`
    query NotFoundMetaData {
      site {
        siteMetadata {
          title
          emailAddress
          emailSubject
        }
      }
      file(relativePath: { eq: "tower-bridge-sunset.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const {emailAddress, emailSubject} = notFoundData.site.siteMetadata;
  return (
    <>
      <SEO title="404: Not found" />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-screen-xl mx-auto ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <Navigation />

            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  Unroll Digital
                </h2>
                <h2 className="pt-4 text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none md:text-4xl">
                  That page isn't found
                </h2>

                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href={`mailto:${emailAddress}?subject=${encodeURI(
                        emailSubject
                      )}`}
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Img
            fluid={notFoundData.file.childImageSharp.fluid}
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            alt="People making notes around a table - Credit: Dylan Gillis, Unsplash"
          />
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
